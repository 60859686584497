import Img1 from "../../images/GalleryPage/1.jpg";
import Img2 from "../../images/GalleryPage/2.jpg";
import Img3 from "../../images/GalleryPage/3.jpg";
import Img4 from "../../images/GalleryPage/4.jpg";
import Img5 from "../../images/GalleryPage/5.jpg";
import Img6 from "../../images/GalleryPage/6.jpg";
import Img7 from "../../images/GalleryPage/7.jpg";
import Img8 from "../../images/GalleryPage/8.jpg";
import Img9 from "../../images/GalleryPage/9.jpg";
import Img10 from "../../images/GalleryPage/10.jpg";
import Img11 from "../../images/GalleryPage/11.jpg";
import Img12 from "../../images/GalleryPage/12.jpg";
import Img13 from "../../images/GalleryPage/13.jpg";  
import Img14 from "../../images/GalleryPage/14.jpg";
import Img15 from "../../images/GalleryPage/15.jpg";
import Img16 from "../../images/GalleryPage/16.jpg";
import Img17 from "../../images/GalleryPage/17.jpg";
import Img18 from "../../images/GalleryPage/18.jpg";
import Img19 from "../../images/GalleryPage/19.jpg";
import Img20 from "../../images/GalleryPage/20.jpg";
import Img21 from "../../images/GalleryPage/21.jpg";
import Img22 from "../../images/GalleryPage/22.jpg";
import Img23 from "../../images/GalleryPage/23.jpg";
import Img24 from "../../images/GalleryPage/24.jpg";
import Img25 from "../../images/GalleryPage/25.jpg";

export const galleryImg = [
  {
    img: Img1,
    id: 1,
  },
  {
    img: Img2,
    id: 2,
  },
  {
    img: Img3,
    id: 3,
  },
  {
    img: Img4,
    id: 4,
  },
  {
    img: Img5,
    id: 5,
  },
  {
    img: Img6,
    id: 6,
  },
  {
    img: Img7,
    id: 7,
  },
  {
    img: Img8,
    id: 8,
  },
  {
    img: Img9,
    id: 9,
  },
  {
    img: Img10,
    id: 10,
  },
  {
    img: Img11,
    id: 11,
  },
  {
    img: Img12,
    id: 12,
  },
  {
    img: Img13,
    id: 13,
  },
  {
    img: Img14,
    id: 14,
  },
  {
    img: Img15,
    id: 15,
  },
  {
    img: Img16,
    id: 16,
  },
  {
    img: Img17,
    id: 17,
  },
  {
    img: Img18,
    id: 18,
  },
  {
    img: Img19,
    id: 19,
  },
  {
    img: Img20,
    id: 20,
  },

  {
    img: Img21,
    id: 21,
  },
  {
    img: Img22,
    id: 22,
  },
  {
    img: Img23,
    id: 23,
  },
  {
    img: Img24,
    id: 24,
  },
  {
    img: Img25,
    id: 25,
  },
];
