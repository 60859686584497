import React from "react";
import TestimonialBox from "./TestimonialBox";

function TestimonialBoxes() {
  return (
    <>
   {/* <TestimonialBox
        text=" “This Product has a good innovation as per the design and user friendly and easy to operate”"
        name="Subhas"
        job="AGM- Agriculture DEPT AP"
      />*/}
      <TestimonialBox
        text=" “This Product is absolute amazing in the reducing the efforts of farmers and can be available at very lower cost compared to any product currently in market”"
        name="Shaik Subhani"
        job="Farmer"
      />
     
    </>
  );
}

export default TestimonialBoxes;